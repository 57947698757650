import React from 'react'
import '../../css/Pixl_Visual.css'
import pixl_bg_video from '../../assets/about_us/fire.mp4'

const Pixl_Visual = () => {
  return (
    <div className='Pixl_Visual-conatiner'>
        <div className='pixl-visual-bg-video'>
            <video muted autoPlay loop>
                <source src={pixl_bg_video} type='video/mp4'/>
            </video>
        </div>
        <div className='Pixl_Visual'>
        <div className='head'>
            <h2>About</h2>
            <h1>PIXL VISUALEFFECTS</h1>
        </div>
        <div className='physics-meets-fantasy'>
            <div className='physics'>
                <h1>Where Physics Meets Fantasy</h1>
                <p>Remember that moment in the <span>theater when you forgot you were watching effects?</span> When the line between <span>real and digital simply... vanished?</span> That's what gets us out of bed in the morning.</p>
                <p>At <span>PIXL VISUALEFFECTS</span>, <span>we're not just creating simulations – we're crafting moments that make audiences forget to breathe</span>. Those split seconds where <span>reality and imagination become one?</span> That's our sweet spot.</p>
                <div  className='btn-container'>
                    <button>The Innovators</button>
                </div>
            </div>
        </div>
        </div>
    </div>
  )
}

export default Pixl_Visual