import React, { useState, useEffect } from 'react';
import Nav from '../Componets/Nav'
import Footer from '../Componets/Footer';
import Slider from '../Componets/Home/Slider'
import { Helmet } from 'react-helmet';
import ImageComparisonWithSlider from '../Componets/ImageComparisonWithSlider';




const Home = () => {
  
  // Store the meta description in state
  const [metaDescription, setMetaDescription] = useState("");

  // You can dynamically update the meta description if needed
  useEffect(() => {
    // You could call an API or some logic to update the description here
    setMetaDescription("PIXL VISUALEFFECTS is a leading VFX studio based in Pondicherry, India, specializing in high-end FX simulations, Houdini effects, and advanced CGI for film, gaming, and immersive experiences. Our team transforms complex challenges into breathtaking digital entertainment.");
  }, []); // This effect runs once when the component is mounted


  return (
    <>
      {/* ---------------- SEO ------------------ */}
      <Helmet>
        <title>Advanced VFX Solutions & FX Simulations | Pixal VFX</title>
        <meta name="description" content={metaDescription} />
      </Helmet>

      {/* ---------------SEO END---------------- */}
    <Nav/>
    <Slider/>
   <Footer/>
    </>
  )
}

export default Home