import React from 'react'
import '../../css/Inquire_Now.css'
const Inquire_Now = () => {
  return (
    <div className='Inquire-Now-container'>
        <div className='Inquire-Now'>
            <form >
                <div className='head'>
                    <h1>Inquire Now</h1>
                </div>
                <div className='row'>
                    <div className='field'>
                        <label htmlFor='first_name_Input'>First Name*</label>
                        <input type='text' id='first_name_Input' placeholder='First Name'/> 
                    </div>
                    <div className='field'>
                        <label htmlFor='last_name_Input'>Last Name*</label>
                        <input type='text' id='last_name_Input' placeholder='Last Name'/>
                    </div>
                </div>
                <div className='row'>
                    <div className='field'>
                        <label htmlFor='Email_Input'>Email Address*</label>
                        <input type='email' id='Email_Input' placeholder='Enter Your Email Address'/> 
                    </div>
                </div>
                <div className='row'>
                    <div className='field'>
                        <label htmlFor='Phone_Number_Input'>Phone Number*</label>
                        <input type='number' id='Phone_Number_Input' placeholder='Enter Your Phone Number'/> 
                    </div>
                </div>
                <div className='row'>
                    <div className='field'>
                        <label htmlFor='Company_Name_Input'>Company Name*</label>
                        <input type='text' id='Company_Name_Input' placeholder='Enter the Company Name'/> 
                    </div>
                </div>
                <div className='row'>
                    <div className='field'>
                        <label htmlFor='project_details_Input'>Project Details*</label>
                        <input type='text' id='project_details_Input' placeholder='Enter your Project Details'/> 
                    </div>
                    </div>
                    <div className='row'>
                    <div className='field'>
                        <label htmlFor='message_Input'>Message*</label>
                        <textarea id='message_Input' placeholder='Enter your Feedback'/> 
                    </div>
                    </div>
                    <div className='row'>
                    <button type='submit'>Submit</button>
                    </div>
                
            </form>
        </div>
    </div>
  )
}

export default Inquire_Now