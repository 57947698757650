import React, { useState } from "react";
import logo_Img from "../assets/logo/logo.png";
import "../css/Nav.css";
import { NavLink, useNavigate } from "react-router-dom";

const Nav = () => {
  const Navigation = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenProductMenu, setIsOpenProductMenu] = useState(false);
  const [isOpenCarriersMenu, SetisOpenCarriersMenu] = useState(false);

  return (
    <nav>
      <div className="nav-container">
        <div className="nav">
          <div className="left">
            <div className="logo">
              <img
                src={logo_Img}
                alt="VFX studio"
                onClick={() => Navigation("/")}
              />
            </div>
          </div>
          <input type="checkbox" id="controllCheckBox" checked={isOpen} />
          <div className="right">
            <div
              className="menu-icon"
              onClick={() => {
                isOpen === true ? setIsOpen(false) : setIsOpen(true);
              }}
            >
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
            </div>
            <div
              className="menu-overlay"
              onClick={() => setIsOpen(false)}
            ></div>
            <div className="menu-bar">
              <ul>
                <li>
                  <NavLink to="/" exact activeClassName="active">
                    Home
                  </NavLink>{" "}
                </li>
                <li>
                  <NavLink to="/about-us" activeClassName="active">
                    about us
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/from-the-founder" activeClassName="active">
                    from the founder
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/show-reel" activeClassName="active">
                    Showreel
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/gaming" activeClassName="active">
                    Gaming
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="active"
                    onClick={() =>
                      isOpenProductMenu == true
                        ? setIsOpenProductMenu(false)
                        : setIsOpenProductMenu(true)
                    }
                  >
                    Products{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </NavLink>
                  <input
                    type="checkbox"
                    id="productHandleDropdown"
                    checked={isOpenProductMenu}
                  />
                  <div className="drop-down">
                    <div
                      className="overflow"
                      onClick={() => setIsOpenProductMenu(false)}
                    ></div>
                    <div className="list">
                      <ul>
                        <li>
                          <NavLink to="https://yalifx.com/" activeClassName="active">
                            yalifx
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/" activeClassName="active">
                            innovations
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li>
                  <NavLink
                    activeClassName="active"
                    onClick={() =>
                      isOpenCarriersMenu == true
                        ? SetisOpenCarriersMenu(false)
                        : SetisOpenCarriersMenu(true)
                    }
                  >
                    Carriers{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                      
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </NavLink>
                  <input
                    type="checkbox"
                    id="CarriersHandleDropdown"
                    checked={isOpenCarriersMenu}
                  />
                  <div className="drop-down">
                    <div
                      className="overflow"
                      onClick={() => SetisOpenCarriersMenu(false)}
                    ></div>
                    <div className="list">
                      <ul>
                        <li>
                          <NavLink to="/" activeClassName="active">
                            jobs
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/" activeClassName="active">
                            internship
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/" activeClassName="active">
                            production Training
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li>
                  <NavLink to="/from-the-founder" activeClassName="active">
                    Charity
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact-us" activeClassName="active">
                    contact us
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
