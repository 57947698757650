import React from "react";
import Nav from "../Componets/Nav";
import Footer from "../Componets/Footer";
import "../css/Showreel.css";
const Showreel = () => {
  return (
    <>
      <Nav />
      <div className="showreel-container">
        <div className="head">
          <h1>Show Reel</h1>
        </div>
        <div className="showreel">
          <div className="list">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/OnYg1uUsYLo?si=IFG7CsqQD-ZdhqM3"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Showreel;
