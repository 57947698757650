import React from "react";
import '../css/Gaming.css'
import Nav from "../Componets/Nav";
import Footer from "../Componets/Footer";

const Gaming = () => {
  return (
    <>
    <Nav />
    <div className="Gaming-container">
      <div className="head">
        <h1>Gaming</h1>
      </div>
      <div className="Gaming">
        <div className="list">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/jm1FHT43HkA?si=ooTeEk2YDfNKj9Jn"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
    <Footer />
    </>
  );
};

export default Gaming;
