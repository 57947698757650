import React from "react";
import "../../css/Address.css";
// Img
import Email_Img from '../../assets/icon/email.png'
import Location_Img from '../../assets/icon/location.png'
const Address = () => {
  return (
    <div className="Address-container">
      <div className="Address">
        <div className="head">
          <h1>
            Located in the heart of Pondicherry, where French colonial charm
            meets cutting-edge technology.
          </h1>
        </div>
        <div className="way-to-connect">
          <h2>Ways to Connect</h2>
          <div className="items">
            {/* left */}
            <div className="left">
              <div className="list-head">
                <img src={Location_Img} alt="" />
                <h1>Visit Our Studio</h1>
              </div>
              <div className="location">
                <p>
                  PIXL VISUALEFFECTS,<br/>
                No. 30, Second Floor,<br/>
                 Subbaiah Salai,<br/>
                  Pondicherry 605002,<br/>
                   India.
                </p>
              </div>
            </div>
            {/* right */}
            <div className="right">
                <div className="list-head">
                <img src={Email_Img} alt="" />
                    <h1>Start a Conversation</h1>
                </div>
                <div className="contact-info">
                    <p>Email: <span>business@pixlvfx.com</span></p>
                    <p>Phone: <span>+91 413 296 1868</span></p>
                    <p>Website: <span> www.pixlvfx.com</span></p>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Address;
