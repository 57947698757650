import React from "react";
import "../../css/Slider.css";
import Bg_Video from '../../assets/pixl_videos/pixl_fx_low.mp4'
import ImageComparisonWithSlider from "../ImageComparisonWithSlider";

const Slider = () => {
  return (
    <div className="Slider-container">
      <div className="slider_bg_video">
        <video muted loop autoPlay>
          <source
            src={Bg_Video}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="Slider">
        <div className="left">
          <div className="head">
            <h3>Welcome to</h3>
            <h1>PIXL VISUAL EFFECTS</h1>
          </div>
          <div className="content">
            <p>
              At PIXL VISUAL EFFECTS, we specialize in delivering{" "}
              <span>high-end FX simulations</span> for the{" "}
              <span>film, gaming, and immersive experience</span> industries.
              Using the latest technologies like Houdini, we bring complex
              water, fire, smoke, and other physics-based effects to life,
              making every frame breathtakingly realistic. Whether you're
              producing a{" "}
              <span>
                blockbuster movie, creating an immersive VR experience,
              </span>{" "}
              or developing the next hit game, our expertise ensures that your{" "}
              <span>vision becomes a reality.</span>
            </p>
          </div>
          <div className="btn-container">
            <div className="btn">Visual Journey</div>
            <div className="btn">Explore our innovations</div>
          </div>
        </div>
        <div className="right"></div>
      </div>
    </div>

  );
};

export default Slider;
