import React from 'react'
import '../../css/Our_story.css'
import Team_img from '../../assets/about_us/professional_VFX _artists.jpeg'

const Our_story = () => {
  return (
    <div className='Our_story-container'>
        <div className='Our_story'>
        <div className='left'>
        <div className='head'>
            <h1>Our Story</h1>
            <p><span>We started with a simple belief</span>: that <span>technical excellence and creative vision</span> aren't opposites – <span>they're dance partners</span>. Our founders spent years in the trenches, wrestling with the same challenges you face</p>
        </div>
            <div  className='img'>
                <img src={Team_img} alt=''/>
                <div className='layer'></div>
                <div className='layer'></div>
            </div>
        </div>
        <div className='right'>
        <div className='head'>
            <h1>Our Story</h1>
        </div>
        <div className='content'>
            <p><span>We started with a simple belief</span>: that <span>technical excellence and creative vision</span> aren't opposites – <span>they're dance partners</span>. Our founders spent years in the trenches, wrestling with the same challenges you face</p>
                <ul>
                    <li>Simulations that looked "too digital"</li>
                    <li>Effects that wouldn't scale</li>
                    <li>Tools that fought against creativity</li>
                    <li>Deadlines that seemed impossible</li>
                </ul>
            <p><span>So we built what we wished existed</span>: a <span>powerhouse FX studio</span> where technical precision meets artistic freedom.</p>
        </div>
        </div>
        
      
        
        </div>
    </div>
  )
}

export default Our_story