import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../css/footer.css";
import footerLogo from "../assets/logo/logo.png";
import footerInstagram from "../assets/icon/instagram-light.png";
import footerFacebook from "../assets/icon/facebook_light.png";
import footerLinkedIn from "../assets/icon/linkedin-light.png";
import footerYoutube from "../assets/icon/youtube-light.png";
import footerWhatsapp from "../assets/icon/whatsapp-light.png";

const Footer = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState({});

  // useEffect(() => {
  //     const handleResize = () => {
  //         setIsMobile(window.innerWidth <= 767);
  //     };

  //     handleResize();
  //     window.addEventListener('resize', handleResize);

  //     return () => {
  //         window.removeEventListener('resize', handleResize);
  //     };
  // }, []);

  const toggleDropdown = (menu) => {
    setDropdownOpen((prev) => ({
      ...prev,
      [menu]: !prev[menu],
    }));
  };

  // Define menu data with paths
  const menuData = [
    {
      title: "CONTENT",
      items: [
        // { name: 'Calendar of festivities', path: '/calendar' },
      ],
    },
    {
      title: "INFORMATION",
      items: [{ name: "About us", path: "/about-us" }],
    },
    {
      title: "LEGAL",
      items: [
        // { name: 'Terms of use', path: '/terms' },
        // { name: 'License agreement', path: '/license' },
        // { name: 'Privacy policy', path: '/privacy' },
        // { name: 'Copyright information', path: '/copyright' },
      ],
    },
    {
      title: "SUPPORT",
      items: [
        { name: "Contact", path: "/contact-us" },
    ],
    },
  ];

  return (
    <footer>
      <div className="footer-container">
        <div className="left">
          <div className="logo">
            <img src={footerLogo} alt="logo" />
          </div>
          <div className="social-media">
            <div className="link">
              <img src={footerInstagram} alt="instagram" />
            </div>
            <div className="link">
              <img src={footerYoutube} alt="youtube" />
            </div>
            <div className="link">
              <img src={footerLinkedIn} alt="linkedin" />
            </div>
            <div className="link">
              <img src={footerWhatsapp} alt="whatsapp" />
            </div>
            <div className="link">
              <img src={footerFacebook} alt="facebook" />
            </div>
          </div>
          <div className="text">
            <p>Get exclusive assets sent straight to your inbox</p>
          </div>
        </div>
        <div className="right">
          {menuData.map((menu) => (
            <div className="menu-links" key={menu.title}>
              <ul>
                <li className="head" onClick={() => toggleDropdown(menu.title)}>
                  {menu.title}{" "}
                  {isMobile &&
                    {
                      /*  <img src={menuDownIcon} alt='' />*/
                    }}
                </li>
                <div
                  className={`footer-dropdown ${
                    dropdownOpen[menu.title] ? "footer-dropdown-active" : ""
                  }`}
                >
                  {menu.items.map((item, index) => (
                    <li key={index}>
                      <Link to={item.path}>{item.name}</Link>
                    </li>
                  ))}
                </div>
              </ul>
            </div>
          ))}
          <div className="google_map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1951.8569783702308!2d79.82931762844066!3d11.924983938626104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5361876b8f79bf%3A0x45821583613f78cd!2sPIXL%20VFX!5e0!3m2!1sen!2sin!4v1731757779630!5m2!1sen!2sin"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
      <div className="line"></div>
      <div className="copyright">
        <p>&copy; Copyright © 2025 Pixl visual Effects. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
